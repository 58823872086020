import React from 'react';

const Logo = () => {
  return (
    <svg height="100%" viewBox="0 0 453 453" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M139.864 162.133L226.5 248.581L313.136 162.133L339.75 188.747L226.5 301.997L113.25 188.747L139.864 162.133Z"
        fill="#111A2B"
      />
    </svg>
  );
};

export default Logo;
